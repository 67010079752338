import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = ({ data }) => {
  const page = data.markdownRemark

  return (
    <Layout>
      <SEO title={page.frontmatter.title} />
      <div className="p-8 max-w-2xl mx-auto page">
        <h2 className="text-4xl text-gray-900 font-bold uppercase">
          {page.frontmatter.title}
        </h2>
        <div
          className="text-gray-700 mt-8"
          dangerouslySetInnerHTML={{
            __html: page.html
          }}
        />
      </div>
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
